import { Avatar, Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { image_url, updateUser, uploads } from "../services/endpoints";
import { setUser } from "../store/auth";
import { setLoading } from "../store/load";
import { pxToRem } from "../theme/typography";
import Input, { CustomPhoneInput, DatePickerInput, DropDownInput } from "../components/Login/Input";
import hit from "../services/apiManager";
import showToast from "../utils/showToast";
import moment from "moment";
import DatePickerModal from "../components/DatePickerModal";

export default function EditProfile() {
    const navigate = useNavigate()
    const user = useSelector(state => state?.auth?.user)
    const [profilePic, setProfilePic] = useState("")
    const [coverPic, setCoverPic] = useState("")
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [open, setOpen] = useState(false)
    const [dob, setDob] = useState(new Date())
    const [bio, setBio] = useState("")
    const [grade, setGrade] = useState("")
    const [accountType, setAccountType] = useState(null)
    const [grades, setGrades] = useState([
        { name: "Pre-K" },
        { name: "Kindergarten" },
        { name: "1st" },
        { name: "2nd" },
        { name: "3rd" },
        { name: "4th" },
        { name: "5th" },
        { name: "6th" },
        { name: "7th" },
        { name: "8th" },
        { name: "9th" },
        { name: "10th" },
        { name: "11th" },
        { name: "12th" },
    ])
    const [graduation_year, setGraduation_year] = useState("")
    const [phone, setPhone] = useState("")
    const dispatch = useDispatch()

    useEffect(() => {
        console.log("userrrrrrrr", user)
        setProfilePic(user?.profilePic)
        setCoverPic(user?.coverPic)
        setFirstName(user?.firstName)
        setBio(user?.bio)
        setPhone(user?.phone)
        setLastName(user?.lastName)
        setDob(user?.dob)
        setGrade(user?.grade)
        setGraduation_year(user?.graduation_year)
        setAccountType(user?.userType)
    }, [user])

    const submit = async () => {
        let apiDob = (moment(dob).unix() * 1000)
        try {
            dispatch(setLoading(true))
            let res = await hit(updateUser, "patch", { firstName, lastName, bio, phone, profilePic, coverPic, "dob": apiDob, grade, graduation_year })
            if (res?.err) {
                showToast(res?.msg, "error")
            } else {
                dispatch(setUser({ user: res?.data }))
                showToast("Updated successfully!")
                navigate(-1)
            }
        } catch (e) { }
        finally {
            dispatch(setLoading(false))
        }
    }

    const uploadImage = async (image, selected) => {
        dispatch(setLoading(true))
        try {
            let formdata = new FormData()
            formdata.append("file", image)
            let res = await hit(uploads, "post", formdata)
            if (!res.err) {
                if (res?.data?.length > 0) {
                    if (selected == 0) {
                        setCoverPic(res?.data[0])
                    } else {
                        setProfilePic(res?.data[0])
                    }
                }
            } else {
                showToast(res?.msg, "error")
            }
        } catch (err) { } finally {
            dispatch(setLoading(false))
        }
    }

    const handleFile = async (e, selected) => {
        try {
            if (e.target.files[0]) {
                let file = e.target.files[0]
                if (file?.size <= 10000000) {
                    uploadImage(e.target.files[0], selected)
                } else {
                    showToast("Image cannot be more than 10mb", "warning")
                }
            }
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <Box sx={{ px: "5%", pt: "20px", display: "flex", pr: "10%", overflowY: "auto", flex: 1, width: "100%", background: "linear-gradient(119deg,#E6F6F9,white,rgb(253,243,213))" }}>
            <Box sx={{ flex: 1, minHeight: "100px" }}>
                <Box sx={{ height: "270px", borderRadius: "5px", width: "100%", objectFit: "cover" }} component="img" src={image_url + coverPic} />
                <Box sx={{ position: "relative", marginX: "auto" }}>
                    <Typography color={"primary"} sx={{ fontSize: pxToRem(14), fontWeight: "500", marginTop: "10px" }}>Change Cover Pic</Typography>
                    <input type="file" id="file-selector" accept="image/png, image/jpeg" onChange={(e) => handleFile(e, 0)} style={{ height: "80px", width: "100%", marginX: "auto", top: 0, position: "absolute", left: 0, opacity: 0, zIndex: 111 }}></input>
                </Box>
                <Box sx={{ width: "100%", position: "relative", marginTop: "20px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                    <Avatar src={image_url + profilePic} sx={{ height: "100px", border: "3px solid white", width: "100px" }} >
                    </Avatar>
                    <Box component={"img"} sx={{ position: "absolute", top: -40, left: "45%" }} src="assets/edit/edit.svg" />
                </Box>
                <Box sx={{ position: "relative", marginX: "auto" }}>
                    <Typography color={"primary"} sx={{ fontSize: pxToRem(14), fontWeight: "500", marginTop: "10px" }}>Change Profile Pic</Typography>
                    <input type="file" id="file-selector" accept="image/png, image/jpeg" onChange={(e) => handleFile(e, 1)} style={{ height: "80px", width: "100%", marginX: "auto", top: 0, position: "absolute", left: 0, opacity: 0, zIndex: 111 }}></input>
                </Box>
                <br /><br />
                <Button
                    onClick={() => { submit() }}
                    sx={{ fontSize: pxToRem(16), borderRadius: "5px", height: "45px", width: "200px" }}
                    variant="contained">
                    Save
                </Button>
                <Box sx={{ height: "20px" }} />
            </Box>
            <Box sx={{ flex: 1, marginLeft: "5%", }}>
                <Box sx={{ display: "flex", px: "10px", py: "20px", flexDirection: "column", background: "white", boxShadow: "0px 5px 20px #26262626", borderRadius: "5px", minHeight: "100px" }}>
                    <Input
                        value={firstName}
                        onChange={v => { setFirstName(v) }}
                        titleStyle={{ textAlign: "left" }}
                        title="First Name"
                        img={"assets/signup/user.svg"}
                    /><br />
                    <Input
                        value={lastName}
                        onChange={v => { setLastName(v) }}
                        titleStyle={{ textAlign: "left" }}
                        title="Last Name"
                        img={"assets/signup/user.svg"}
                    /><br />
                    <DatePickerInput
                        onClick={() => { setOpen(true) }}
                        titleStyle={{ textAlign: "left" }}
                        value={moment(dob).format("DD/MM/YYYY")}
                        title="Date of birth"
                        img={"assets/signup/grade.svg"}
                    /><br />
                    {accountType == "student" && <><br />
                        <DropDownInput
                            onChange={v => { setGrade(v) }}
                            value={grade}
                            options={grades.map(x => x.name)}
                            titleStyle={{ textAlign: "left" }}
                            title="Grade"
                            img={"assets/signup/grade.svg"}
                        /><br />
                        <Input
                            value={graduation_year}
                            type={"Number"}
                            onChange={t => { setGraduation_year(t) }}
                            titleStyle={{ textAlign: "left" }}
                            placeholder={"Graduation Year"}
                            title="Graduation Year"
                            img={"assets/signup/grade.svg"}
                        /><br />
                    </>}
                    <Input
                        inputContainerStyle={{ height: "100px" }}
                        value={bio}
                        onChange={v => { setBio(v) }}
                        multiline={true}
                        titleStyle={{ textAlign: "left" }}
                        title="Bio"
                        img={"assets/signup/user.svg"}
                    /><br />
                    <Input
                        value={user?.email}
                        titleStyle={{ textAlign: "left" }}
                        title="Email"
                        img={"assets/login/mail.svg"}
                    /><br />
                    <CustomPhoneInput
                        titleStyle={{ textAlign: "left" }}
                        value={phone}
                        img={"assets/signup/phone.svg"}
                        onChange={v => setPhone(v)}
                        title="Mobile Number"
                    /><br />
                    <Box sx={{ height: "20px" }} />
                </Box>
            </Box>
            <DatePickerModal
                open={open}
                setOpen={setOpen}
                value={moment(dob)}
                onChange={v => {
                    if (moment(v).toDate() > moment().toDate()) {
                        return
                    }
                    setDob(moment(v).toDate())
                }}
            />
        </Box>
    )
}